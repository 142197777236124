<template>
    <div class="drive-manager">
        <els-bread class="_bread"></els-bread>
        <div class="_header d-header">
            <els-button-group
                    type="primary"
                    size="small"
                    :column="buttons"
            ></els-button-group>
            <div class="h--search">
                <el-input
                        v-model="searchValue"
                        prefix-icon="el-icon-search"
                        :placeholder="lang.driverName"
                        size="small"
                ></el-input>
            </div>
        </div>
        <div class="_content d-content">
            <els-table
                    ref="dRefs"
                    :column="column"
                    :onload="onload"
                    menuWidth="180px">
                <template v-slot:menu="{ row }">
                    <el-button
                            type="text"
                            @click.stop="handleRowEdit(row)"
                    >{{lang.edit}}
                    </el-button>
                    <el-divider direction="vertical"></el-divider>
                    <el-button type="text" @click.stop="handRowDel(row)">{{lang.delete}}</el-button>
                    <el-divider direction="vertical"></el-divider>
                    <el-button type="text" @click.stop="handRowIssue(row)">{{lang.issue}}</el-button>
                </template>
                <template v-slot:config="{ row }">
                    <el-button type="text" @click.stop="handViewConfig(row)" v-if="row.config">{{lang.view}}</el-button>
                </template>
            </els-table>
        </div>
        <els-form-dialog
                :visible.sync="dialogVisible"
                :title="dialogTitle"
                :cancel="lang.cancel" :submit="lang.confirm"
                @submit="handleSubmit">
            <els-form
                    :ctx="{lang: $t('d')}"
                    v-if="dialogVisible"
                    ref="elsFormRef"
                    v-model="formData" @change-ftpUrl="changeFtpUrl"
                    :column="formColumn"
            ></els-form>
        </els-form-dialog>
        <el-dialog
                :title="issueDialogTitle"
                :visible.sync="issueDialogVisible" :close-on-click-modal="false"
                width="800px">
            <div class="issue--content">
                <els-table v-if="issueDialogVisible"
                           ref="issueRefs"
                           :column="issueColumn"
                           :onload="issueOnload" :showIndex="false" :showCheckbox="true" :showMenu="false"
                           @change="selCheck">
                </els-table>
            </div>
            <div slot="footer" class="dialog-footer">
                <div class="issue--num--text">{{lang.selGateway}}: {{selTableData.length}}</div>
                <el-button @click="issueDialogVisible = false" class="btn-normal">{{lang.cancel}}</el-button>
                <el-button type="primary" @click="handleIssueSubmit" class="btn-normal">{{lang.issue}}</el-button>
            </div>
        </el-dialog>
        <el-dialog
                :title="configDialogTitle"
                :visible.sync="configDialogVisible" :close-on-click-modal="false"
                width="800px">
            <div class="editor-view" v-if="configDialogVisible">
                <els-monaco-editor :value="currentRow.config" theme="vs"  lang="json"
                     :disabled="true" :showHeader="false" :options="{contextmenu:false,scrollBeyondLastLine:false}"></els-monaco-editor>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="configDialogVisible = false" class="btn-normal">{{lang.cancel}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import elsBread from "@/components/els-breadcrumb/main.vue";
    import elsTable from "@/components/els-table/main.vue";
    import elsMonacoEditor from "@/components/els-monaco-editor/main.vue";
    import mixinsI18n from "@/mixins/i18n.js";
    import {
        getDriveList,
        driveAdd,
        driveUpdate,
        deleteDrive,
        getDriveGateways,
        driveIssue,
        queryDriverNameList
    } from "@/api/manager/drive-manager.js";
    import elsFormDialog from "@/components/els-form-dialog/main.vue";
    import elsForm from "@/components/els-form/main.vue";

    export default {
        name: "drive-manager",
        mixins: [mixinsI18n],
        components: {elsBread, elsTable, elsFormDialog, elsForm, elsMonacoEditor},
        data: () => {
            return {
                PAGE_NAME: "driveManagePage",
                columnBread: [],
                searchValue: "",
                dialogVisible: false,
                dialogTitle: "",
                formData: {},
                fileList: [],
                existDriveData: [],
                issueDialogVisible: false,
                issueDialogTitle: "",
                selTableData: [], //下发弹框选择的数据
                currentRow: {},
                configDialogTitle: "",
                configDialogVisible: false,
            }
        },
        computed: {
            ...mapState(["breadMenus"]),
            buttons() {
                const addBtn = {label: this.lang.addDriver, handle: this.handleAdd};
                return [addBtn];
            },
            column() {
                const name = {prop: "name", label: this.lang.driverName};
                const fileName = {prop: "fileName", label: this.lang.fileName};
                const version = {prop: "version", label: this.lang.version};
                const config = {prop: "config", label: this.lang.conPara, hasSlot: true};
                const remark = {prop: "remark", label: this.lang.driveDes};
                const updateTime = {prop: "updateTime", label: this.lang.lastUpdateTime};
                return [name, fileName, version, config, remark, updateTime]
            },
            driverStatus() {
                return [{
                    label: "", value: 0
                }, {
                    label: this.lang.success, value: 1, className: "blue-color"
                }, {
                    label: this.lang.fail, value: 2, className: "red-color"
                }, {
                    label: this.lang.success, value: 3, className: "blue-color"
                }, {
                    label: this.lang.fail, value: 4, className: "red-color"
                }]
            },
            formColumn() {
                const fileName = {
                    prop: "ftpUrl",
                    label: this.lang.driveFile,
                    tag: "self-upload",
                    size: "mini",
                    accept: "",
                    limitSize: 200,
                    beforeUpload: this.beforeUpload,
                    uploadSuccess: this.uploadSuccess,
                    btnText: this.lang.uploadFile,
                    rules: [
                        {
                            required: true,
                            message: this.lang.fileNotUpload,
                            trigger: "blur",
                        },
                    ],
                    fileList: this.fileList,
                    ftpRequest: true,
                    resultKey: "ftpUrl"
                };
                const name = {
                    prop: "name",
                    label: this.lang.driverName,
                    required: true,
                    disabled: true,
                    // "fetch-suggestions": this.querySearchAsync,
                    // on: {
                    //     select: this.handleSelect
                    // },
                    rules: [{max: 50, message: this.lang.max50, trigger: "blur"}]
                };
                const version = {
                    prop: "version", label: this.lang.version, required: true, disabled: true,
                    rules: [{max: 50, message: this.lang.max50, trigger: "blur"}]
                };
                const remark = {
                    prop: "remark", label: this.lang.driveDes, required: true, type: "textarea",
                    rules: [{max: 50, message: this.lang.max50, trigger: "blur"}]
                };
                return [fileName, name, version, remark]
            },
            issueColumn() {
                const num = {prop: "code", label: this.lang.gatewayNum};
                const name = {prop: "name", label: this.lang.gatewayName};
                const version = {prop: "driverVersion", label: this.lang.issuedVersion};
                const result = {prop: "driverStatus", label: this.lang.issueResults, dict: this.driverStatus};
                return [num, name, version, result]
            }
        },
        watch: {
            searchValue(v) {
                this.reloadTable();
            },
        },
        methods: {
            async onload(page, query) {
                try {
                    const p = {
                        pageNum: page.pageNum,
                        pageSize: page.pageSize,
                        textSearch: this.searchValue,
                    };
                    const res = await getDriveList(p);
                    const list = res.result.data;
                    const total = res.result.total || res.result.data.length;
                    return {list, total}
                } catch (err) {
                    console.log(err);
                    return {list: [], total: 0};
                }
            },
            reloadTable() {
                this.$refs.dRefs && this.$refs.dRefs.apiOnload();
            },
            handleAdd() {
                this.dialogVisible = true;
                this.dialogTitle = this.lang.addDriver;
                this.fileList = [];
                this.formData = {};
            },
            handleRowEdit(row) {
                this.dialogVisible = true;
                this.dialogTitle = this.lang.editDriver;
                const {id, name, fileName, fileSize, ftpUrl, version, remark, config, protocolIds} = row;
                this.formData = {id, name, fileName, fileSize, ftpUrl, version, remark, config, protocolIds};
                this.fileList = [{name: row.fileName, url: row.ftpUrl}];
            },
            handRowDel(row) {
                this.$confirm(this.lang.delMsg, this.lang.tips, {
                    confirmButtonText: this.lang.confirm,
                    cancelButtonText: this.lang.cancel,
                    type: "warning",
                })
                    .then(() => {
                        this.deleteDrive({id: row.id});
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            async deleteDrive(id) {
                try {
                    const res = await deleteDrive(id);
                    this.$message.success(res.msg);
                    this.reloadTable();
                } catch (e) {
                    console.log(e);
                }
            },
            handRowIssue(row) {
                this.currentRow = row;
                this.selTableData = [];
                this.issueDialogTitle = `${this.lang.issueDrive} (${row.name}，${this.lang.currentVersion}：${row.version})`;
                this.issueDialogVisible = true;
            },
            async handleSubmit() {
                try {
                    await this.$refs["elsFormRef"].validate();
                    let res;
                    if (this.formData.id) {
                        res = await driveUpdate({...this.formData});
                    } else {
                        res = await driveAdd({...this.formData});
                    }
                    this.$message({type: "success", message: res.msg});
                    this.dialogVisible = false;
                    this.reloadTable();
                } catch (err) {
                    console.log(err);
                }
            },
            beforeUpload(file) {
                if (file.size === 0) {
                    this.$message.warning(this.lang.fileNotNull);
                    return false;
                }
                const size = file.size;
                if (file.size / 1024 / 1024 > 200) {
                    this.$message.warning(this.lang.fileSizeEx);
                    return false;
                }
                this.formData.fileName = file.name;
                this.formData.fileSize = size;
            },
            uploadSuccess(v) {
                if (v &&(!v.name || !v.version || !v.config || !v.protocolIds) ) {
                    this.$message.error(this.lang.fileMess);
                    setTimeout(() => {
                        this.fileList = [];
                        this.formData = {...this.formData, ftpUrl: "", fileName: "", fileSize: ""};
                    }, 10);
                    return;
                }
                setTimeout(() => {
                    const data = {
                        ...this.formData,
                        name: v.name,
                        version: v.version,
                        remark: v.remark,
                        config: v.config,
                        protocolIds: v.protocolIds
                    };
                    this.formData = data;
                }, 10);
            },
            querySearchAsync(queryString, cb) {
                const list = this.existDriveData;
                const results = queryString ? list.filter(this.createStateFilter(queryString)) : list;
                cb(results);
            },
            createStateFilter(queryString) {
                return (state) => {
                    return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
                };
            },
            handleSelect(item) {
                console.log(item);
            },
            async handleIssueSubmit() {
                if (this.selTableData == 0) {
                    this.$message({type: "warning", message: this.lang.selAGateway});
                    return;
                }
                const selList = this.selTableData.map(item => item.id);
                const params = {
                    driverId: this.currentRow.id,
                    deviceIdList: selList
                }
                const res = await driveIssue(params);
                this.$message({type: "success", message: this.lang.issueCom});
                this.$refs.issueRefs && this.$refs.issueRefs.apiOnload();
            },
            async issueOnload(page, query) {
                try {
                    const p = {
                        pageNum: page.pageNum,
                        pageSize: page.pageSize,
                        driverId: this.currentRow.id,
                    };
                    const res = await getDriveGateways(p);
                    const list = res.result.data;
                    const total = res.result.totalCount || res.result.data.length;
                    return {list, total}
                } catch (err) {
                    console.log(err);
                    return {list: [], total: 0};
                }
            },
            selCheck(r) {
                this.selTableData = r;
            },
            changeFtpUrl(v) {
                if (!v) {
                    this.formData = {...this.formData, size: 0, name: "", version: "", remark: ""};
                }
                if (v) {
                    setTimeout(() => {
                         this.$refs["elsFormRef"].validate();
                    }, 100);
                }
            },
            changeDriverName(v) {
                if (v) {
                    this.$refs["elsFormRef"].validateField("driverName");
                }
            },
            // 获取已存在驱动
            async getExistDriveData() {
                const res = await queryDriverNameList();
                this.existDriveData = [];
                const result = res.result.data;
                result.forEach(item => {
                    const obj = {value: item};
                    this.existDriveData.push(obj)
                })
            },
            // 查看配置
            handViewConfig(row) {
                this.configDialogVisible = true;
                this.configDialogTitle = this.lang.viewCon;
                this.currentRow = row;
            },
        },
        mounted() {
            this.columnBread = this.breadMenus;
        },
    }
</script>

<style scoped lang="scss">
    .drive-manager {
        width: 100%;
        height: 100%;

        .d-header {
            padding: 0 24px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
        }

        .d-content {
            padding: 24px;
            box-sizing: border-box;
            position: relative;
            width: 100%;
            height: calc(100% - 44px - 54px);
        }

        ::v-deep .el-button--text:nth-child(3) {
            color: red;
        }

        .issue--content {
            height: 300px;
        }

        .dialog-footer {
            position: relative;

            .issue--num--text {
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }

        .btn-normal {
            width: 106px;
        }
        .editor-view {
           height: 300px;
           border: 1px solid #ddd;
           border-radius: 8px;
        }
    }

    ::v-deep .el-autocomplete {
        width: 100%;
    }

    ::v-deep .blue-color {
        color: #409EFF;
    }

    ::v-deep .red-color {
        color: red
    }

    ::v-deep .el-dialog .el-dialog__header {
        min-height: 44px;
        height: auto !important;
        padding: 8px 24px !important;
        box-sizing: border-box;

        .el-dialog__title {
            word-break: break-all; //英文
            white-space: pre-wrap; //中文
            padding-right: 4px;
        }
    }

</style>
