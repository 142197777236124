import http from "@/axios/index"
import { URI } from "@/config/axios"

export const getDriveList = (params = {}) => {
    return http({
        url: URI.system + `api/driver/queryPageList`,
        method: 'get',
        params: {
            ...params
        }
    })
}

// add
export const driveAdd = (data) => {
    return http({
        url: URI.system + 'api/driver/add',
        method: 'post',
        data: { ...data }
    })
}

// 编辑
export const driveUpdate= (data) => {
    return http({
        url: URI.system + 'api/driver/update',
        method: 'post',
        data: { ...data }
    })
}

// 删除
export const deleteDrive = (id) => {
    return http({
        url: URI.system + 'api/driver/delete',
        method: 'delete',
        params: id
    })
}

// 查询协议下的网关数据
export const getDriveGateways = (data) => {
    return http({
        url: URI.system + 'api/driver/gateways',
        method: 'get',
        params: { ...data }
    })
}

// 下发
export const driveIssue= (data) => {
    return http({
        url: URI.system + 'api/driverDeviceRecord/add',
        method: 'post',
        data: { ...data }
    })
}

//获取已存在驱动
export const queryDriverNameList = () => {
    return http({
        url: URI.system + 'api/driver/queryDriverNameList',
        method: 'get'
    })
}
